import 'slick-carousel'

const carousel = {
	initLoad() {
		this.initCarousel();
	},

	initCarousel() {
		const $el = $('.js-carousel')
		if($(window).outerWidth() <= 767 && $el.length){
    	$el.slick({
				dots: true,
				infinite: false,
				speed: 300,
				slidesToShow: 1,
				slidesToScroll: 1,
				adaptiveHeight: true
			});
		}
  },
}

module.exports = carousel;
