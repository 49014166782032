import gsap from "gsap";

const animations = {
	initLoad() {
		this.logoAnim();
	},

	logoAnim() {
    const $el = $('.top__logo')
    if($el.length == 0) return false

    const $circle = $('.top__circle')
    const $letters = $('.logo-letter')
    const $claim = $('.top__claim')
    const $scroll = $('.top__scroll')

    const startingWidth = $(window).outerWidth() > 768 ? "100%" : "150%";
    const endingWidth = $(window).outerWidth() > 768 ? "75%" : "120%";

    gsap.set($circle, {
      width: startingWidth,
      paddingBottom: startingWidth
    })

    gsap.set($letters, {
      opacity: 0,
      yPercent: 50
    })

    gsap.set($el, {
      x: 75
    })

    gsap.set($claim, {
      opacity: 0,
      yPercent: 50
    })

    gsap.set($scroll, {
      opacity: 0
    })

    gsap.timeline({
			onComplete: function() {
        animations.circlePulse()
			}
		})
		.addLabel('start')
		.to($circle, {duration: 1.5, width: endingWidth, paddingBottom: endingWidth, ease:"power3.inOut"}, 'start')
		.to($el, {duration: 1, x: 0, ease:"power3.inOut", delay: .5}, 'start')
    .addLabel('middle')
    .to($letters, {yPercent: 0, opacity: 1, duration: .6, stagger: { each: 0.1, ease: 'Power3.inOut' }}, 'middle')
    .to($claim, {opacity: 1, yPercent: 0, duration: .6, delay: 1, ease:"power1.inOut"}, 'middle')
    .addLabel('end')
    .to($scroll, {opacity: 1, duration: .6, ease:"power1.inOut"}, 'end')

  },

  circlePulse(){
    const $circle = $('.top__circle')
    const endingWidth = $(window).outerWidth() > 768 ? "75%" : "120%";
    const bounceWidth = $(window).outerWidth() > 768 ? "85%" : "130%";

    gsap.timeline({
      repeat: -1,
      repeatDelay: 1.5
    })
    .addLabel('start')
		.to($circle, {duration: .4, width: bounceWidth, paddingBottom: bounceWidth, ease:"power1.inOut"}, 'start')
    .addLabel('end')
		.to($circle, {duration: .4, width: endingWidth, paddingBottom: endingWidth, ease:"power1.inOut"}, 'end')
  },

}

module.exports = animations;
