import gsap from "gsap";
import "intersection-observer";
import scrollama from "scrollama";

const menu = {
	scroller: scrollama(),

	initLoad() {
		this.menuInit();
		this.initSideNav()
	},

	initScroll() {
		this.setActiveLabel()
	},

	menuInit() {
		$('.header__hamburger').on('click',() => {
			$('.header__hamburger, .header').toggleClass('opened');
			if($('.header').hasClass('opened')){
				$('.header__menu__links').fadeIn(400)
			} else {
				$('.header__menu__links').fadeOut(400)
			}
		})
	},

	initSideNav() {
		const $el = $('.sideNav')
		if($el.length == 0) return false

		/* COMPOSIZIONE */
		const $sections = $('section')
		Array.prototype.forEach.call($sections, child => {
			const label = $(child).data('title')
			const refLink = $(child).attr('id')

			if(label){
				const linkHtml = `
					<li>
						<a class="goToLink" data-target="#${refLink}" href="javascript:;" title=""><span>${label}</span><i></i></a>
					</li>
				`
				$el.find('ul').append(linkHtml)
			}
		});

		/* ANIMAZIONE DI TUTTI I BULLET*/
		// const $sideNavEls = $('.sideNav a span')
		// const hover = gsap.to($sideNavEls, {x: 0, opacity: 1, duration: .6, paused: true, stagger: { each: 0.1, ease: 'Power3.inOut' }})

		// $el.on("mouseenter", () => hover.play());
		// $el.on("mouseleave", () => hover.reverse());

		/* ANIMAZIONE DEI SINGOLI BULLET */
		$('.sideNav li').on("mouseenter", function() {
			gsap.to($('span', this), {x: 0, opacity: 1, duration: .6, ease: 'Power3.inOut'})
		});
		$('.sideNav li').on("mouseleave", function() {
			gsap.to($('span', this), {x: 10, opacity: 0, duration: .6, ease: 'Power3.inOut'})
		});

	},

	setActiveLabel() {
		const $el = $('.sideNav')
		if($el.length == 0) return false

		menu.scroller
			.setup({
				step: 'section',
				// once: true,
				offset: 0.7,
			})
			.onStepEnter((response) => {
				/* Attivo il dot corrispondente */
				const id = $(response.element).attr('id')

				$(`.sideNav li`).removeClass('active')
				$(`.sideNav a[data-target="#${id}"]`).parent().addClass('active')

				/* Faccio vedere la label in basso a destra */
				const $fixedLabel = $('.currentSection')
				const currentLabel = $(`.sideNav a[data-target="#${id}"] span`).text()

				if(id != 'top'){
					$fixedLabel.html(currentLabel)
				}

				if(id == 'top'){
					$fixedLabel.html('')
				}
			})
	}
}

module.exports = menu;
