import "intersection-observer";
import scrollama from "scrollama";

const common = {
  scroller: scrollama(),

  initReady() {
    this.setFullHeight();
		this.bindScrollingLink();
    this.initEnterViewport();
    this.initModal();
	},
  goToPos: (ref, offset = 0) => {
    const $ref = (typeof ref === 'string') ? $(ref) : ref;

    if ($(ref).length === 0) return false;
    const myPos = $ref.offset().top - offset;

    $('html,body').animate({
      scrollTop: myPos,
    });
  },

  bindScrollingLink() {
    $(document)
      .on('click', '.goToLink', function () {
        const target = $(this).data('target');
        const offset = $(this).data('offset') || 0;

        if (typeof target !== 'undefined') {
          common.goToPos(target, offset);
        }
      });
  },

  initEnterViewport() {
    const $el = $('.anim');
		if($el.length) {
      common.scroller
        .setup({
          step: '.anim',
          // once: true,
          offset: 0.7
        })
        .onStepEnter((response) => {
          // { element, index, direction }
          const delay = $(response.element).data('delay') * 1000 || 0

          setTimeout(() => {
            $(response.element).addClass('in-view')
          }, delay);
        })
    }
  },

  initModal() {
    const $el = $('.modal');
		if($el.length == 0) return false

    const $close = $('.modal__close')
    const $backdrop = $('.modal__backdrop')
    const $overlay = $('.members__image__overlay')

    $close.on('click', () => $el.fadeOut(400))
    $backdrop.on('click', () => $el.fadeOut(400))

    $overlay.on('click', function() {
      const image = $(this).parent().find('img').attr('src')
      const name = $(this).parent().parent().find('.members__name').html()
      const role = $(this).parent().parent().find('.members__role').html()
      const text = $(this).parent().parent().find('.members__text').html()

      $('.modal__image img').attr('src', image)
      $('.modal__title').html(name)
      $('.modal__subtitle').html(role)
      $('.modal__text').html(text)

      $el.fadeIn(450)
    })
  },

  is_touch_device() {
		const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
		const mq = function (query) {
			return window.matchMedia(query).matches;
		};

		if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
			return true;
		}

		// include the 'heartz' as a way to have a non matching MQ to help terminate the join
		// https://git.io/vznFH
		const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
		return mq(query);
	},

  setFullHeight() {
    $('.fullH').height($(window).outerHeight());
  },
}

module.exports = common;
